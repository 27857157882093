<template>
  <div>
    <label
      v-if="label"
      class="form-label"
    >
      {{ label }}
    </label>
    <div
      class="input-group flex-nowrap date-range-block form-group"
      :class="[ inputClass, { 'border border-danger': error || controlledError }]"
    >
      <cp-date-picker
        ref="fromDate"
        v-model="fromVal"
        name="fromDate"
        :error="errors['fromDate']"
        :disabled-dates="fromDisabledDates"
        @input="handleInput"
      />
      <div class="separating">
        <span class="px-1 sep-text bg-light">
          {{ $t('component.cpDateRange.to') }}
        </span>
      </div>
      <cp-date-picker
        ref="toDate"
        v-model="toVal"
        name="toDate"
        :error="errors['toDate']"
        :disabled-dates="toDisabledDates"
        calendar-on-right
        @input="handleInput"
      />
    </div>
    <span
      v-if="error || controlledError"
      class="invalid-feedback d-block"
    >
      {{ error || controlledError }}
    </span>
  </div>
</template>

<script>
import CpDatePicker from '~/components/common/standalone-components/inputs/cp-date-picker';
import CpTimeConverter from '~/mixins/time-converter';
import { checkDateInput } from '~/utilities/date-utils';

export default {
  name: 'DateRange',
  components: {
    CpDatePicker,
  },
  mixins: [
    CpTimeConverter,
  ],
  props: {
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: [String, Object, Array],
      default: '',
    },
    value: {
      type: Array,
      default: () => ([null, null]),
    },
    validate: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    allowValidationByHour: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fromVal: '',
      toVal: '',
      controlledValue: this.value,
      controlledError: '',
    };
  },
  computed: {
    errors() {
      return this.validationErrors.items.reduce(
        (res, error) => {
          res[error.field] = error.msg;
          return res;
        },
        {},
      );
    },
    toDisabledDates() {
      return {
        to: this.fromVal ? new Date(this.fromVal) : null,
      };
    },
    fromDisabledDates() {
      const date = this.allowValidationByHour ? this.setHours(this.toVal, true) : this.toVal;
      return { from: this.toVal ? new Date(date) : null };
    },
  },
  methods: {
    handleInput() {
      const convertedFrom = checkDateInput(this.fromVal) ? this.setHours(this.fromVal) : undefined;
      const convertedTo = checkDateInput(this.toVal) ? this.setHours(this.toVal, true) : undefined;
      this.$emit('input', [convertedFrom, convertedTo]);
    },
    isCpInput() {
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.border-danger {
  padding: 2px;
}
.date-range-block {
  .date-block {
    width: 100%;
  }
  .vdp-datepicker {
    .input-group-text {
      padding: 0 0.3rem;
      font-size: 0.7rem;
    }
  }
  .separating {
    .sep-text{
      display: flex;
      align-items: center;
      margin: 0 5px;
      height: 100%;
      max-height: 38px;
      font-size: 0.894rem;
      font-weight: 400;
      line-height: 1.54;
      color: #627d8e;
      text-align: center;
      white-space: nowrap;
      background-color: #fff;
      border: 1px solid rgba(10, 23, 39, 0.1);
    }
  }
}
</style>
