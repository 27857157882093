<template>
  <div
    id="accreditations"
    class=""
  >
    <accreditation-detail
      ref="accreditationDetail"
      :accreditation="accreditation"
      :operator-id="operatorId"
      :is-loading="isLoading"
      @onDownload="downloadZip"
      @onChangeReview="changeReview"
    />
    <div class="p-2">
      <accreditation-header />
      <accreditation-status-filter
        :attorneys="attorneys"
        @filtersApplied="onFiltersApplied"
      />
      <accreditation-table
        ref="accreditationTable"
        :filters="activeFilters"
        :attorneys="attorneys"
        :assigning-accreditation-id="assigningAccreditationId"
        :downloading="downloading"
        @onViewElem="viewDetail"
        @onDetail="viewDetail"
        @assigneeAttorney="assigneeAttorney"
        @onExportList="exportList"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import AccreditationHeader from './components/head';
import AccreditationStatusFilter from './components/filter';
import AccreditationTable from './components/table';
import AccreditationDetail from './components/accreditation-detail';

export default {
  name: 'Accreditations',
  metaInfo: {
    title: 'Accreditations',
  },
  components: {
    AccreditationHeader,
    AccreditationStatusFilter,
    AccreditationTable,
    AccreditationDetail,
  },
  data() {
    return {
      isReady: false,
      activeFilters: {},
      accreditation: {},
      isLoading: true,
      attorneys: [],
      operatorId: 0,
      assigningAccreditationId: null,
      downloading: false,
    };
  },
  computed: {
    ...mapState('issuersInfo', ['currentToken']),
    ...mapGetters('currentOperator', ['operatorData']),
  },
  mounted() {
    this.getBrokerDealerAttorneys().then(({ data }) => {
      this.attorneys = data;
    });
    this.getBrokerDealerOperatorById({ externalId: this.operatorData.externalId }).then((res) => {
      this.operatorId = res.id;
    });
  },
  methods: {
    ...mapActions(
      'brokerDealerAccreditations',
      ['getBrokerDealerOperatorById',
        'getBrokerDealerAccreditationDetail',
        'getBrokerDealerAccreditationZipUrl',
        'updateBrokerDealerAccreditation',
        'getBrokerDealerAttorneys',
        'putBrokerDealerAttorney',
        'getBrokerDealerAccreditationsListCsv',
      ],
    ),
    onFiltersApplied(filters, dates) {
      this.activeFilters = { ...filters, ...dates };
    },
    viewDetail(accreditationId) {
      this.isLoading = true;
      this.getBrokerDealerAccreditationDetail({ accreditationId }).then((res) => {
        this.isLoading = false;
        this.accreditation = res.data;
      });
      this.$refs.accreditationDetail.$refs.accreditationDetail.show();
    },
    downloadZip(accreditationId) {
      this.getBrokerDealerAccreditationZipUrl({ accreditationId }).then((res) => {
        if (res.data.url) {
          const link = document.createElement('a');
          link.target = '_blank';
          link.download = res.data.url.split('/').pop();
          link.name = res.data.url.split('/').pop();
          link.href = res.data.url;
          link.click();
        }
      });
    },
    changeReview(accreditationId, status, rejectReason, note) {
      this.updateBrokerDealerAccreditation({ accreditationId, status, rejectReason, note }).then(() => {
        this.$refs.accreditationTable.$refs.accreditationTable._updateTableData();
        this.$refs.accreditationDetail.$refs.accreditationDetail.hide();
      });
    },
    assigneeAttorney({ accreditationId, operatorId }) {
      this.assigningAccreditationId = accreditationId;
      let attorney;
      if (operatorId) {
        attorney = this.attorneys.find(item => item.operatorId === operatorId);
      } else {
        attorney = { id: null };
      }
      this.putBrokerDealerAttorney({ accreditationId, attorney }).then(() => {
        this.assigningAccreditationId = null;
      });
    },
    exportList({ items, queryParams, filters }) {
      this.downloading = true;
      const joinData = {
        ...queryParams,
        ...filters,
      };
      const filteredParams = Object.keys(joinData).reduce((res, key) => {
        if (joinData[key] !== '') {
          res[key] = joinData[key];
        }
        return res;
      }, {});
      if (items.length) {
        this.getBrokerDealerAccreditationsListCsv({ params: filteredParams, csv: true }).finally(() => {
          this.downloading = false;
        });
      }
    },
  },
};
</script>
