export const accreditationStatus = {
    names: {
        EXPIRED: 'expired',
        PROCESSING: 'processing',
        REJECTED: 'rejected',
        UPDATES_REQUIRED: 'updates-required',
        VERIFIED: 'verified',
    },
    statuses: [
        { value: 'processing', i18nKey: 'brokerDealer.accreditation.verificationTab.accreditationStatuses.processing' },
        { value: 'verified', i18nKey: 'brokerDealer.accreditation.verificationTab.accreditationStatuses.verified' },
        { value: 'expired', i18nKey: 'brokerDealer.accreditation.verificationTab.accreditationStatuses.expired' },
        { value: 'rejected', i18nKey: 'brokerDealer.accreditation.verificationTab.accreditationStatuses.rejected' },
        {
            value: 'updates-required',
            i18nKey: 'brokerDealer.accreditation.verificationTab.accreditationStatuses.updates-required',
        },
    ],
};
export const investorTypes = {
    types: [
        { value: 'individual', i18nKey: 'brokerDealer.accreditation.verificationTab.investorTypes.individual' },
        { value: 'entity', i18nKey: 'brokerDealer.accreditation.verificationTab.investorTypes.entity' },
    ],
};
export const accreditationNotes = [
    { value: 'processing', i18nKey: 'brokerDealer.accreditation.verificationTab.accreditationStatuses.processing' },
    { value: 'verified', i18nKey: 'brokerDealer.accreditation.verificationTab.accreditationStatuses.verified' },
    { value: 'expired', i18nKey: 'brokerDealer.accreditation.verificationTab.accreditationStatuses.expired' },
    { value: 'rejected', i18nKey: 'brokerDealer.accreditation.verificationTab.accreditationStatuses.rejected' },
];

export const accreditationStatusesColor = {
    Expired: 'badge-secondary',
    Processing: 'badge-yellow',
    'Updates required': 'badge-orange',
    Verified: 'badge-green',
    Rejected: 'badge-red',
    'not-accredited': 'badge-yellow',
};

export const tableFields = [
    {
        key: 'investorName',
        i18nKey: 'brokerDealer.accreditation.table.labels.name',
        sortable: false,
        tdClass: 'text-left align-middle text-break',
        thClass: 'text-left text-nowrap',
        thStyle: { minWidth: '200px', maxWidth: '200px' },
    },
    {
        key: 'investorType',
        i18nKey: 'brokerDealer.accreditation.table.labels.investorType',
        sortable: true,
        tdClass: 'text-left align-middle',
        thClass: 'text-left text-nowrap',
        thStyle: { minWidth: '120px' },
    },
    {
        key: 'accreditationMethod',
        i18nKey: 'brokerDealer.accreditation.table.labels.accreditationMethod',
        sortable: true,
        tdClass: 'text-left align-middle',
        thClass: 'text-left text-nowrap',
        thStyle: { minWidth: '120px' },
    },
    {
        key: 'documentOrigin',
        i18nKey: 'brokerDealer.accreditation.table.labels.documentOrigin',
        sortable: false,
        tdClass: 'text-left align-middle',
        thClass: 'text-left text-nowrap',
        thStyle: { minWidth: '120px' },
    },
    {
        key: 'createdAt',
        i18nKey: 'brokerDealer.accreditation.table.labels.createdAt',
        sortable: true,
        tdClass: 'text-left align-middle',
        thClass: 'text-left text-nowrap',
        thStyle: { minWidth: '120px' },
    },
    {
        key: 'status',
        i18nKey: 'brokerDealer.accreditation.table.labels.status',
        sortable: true,
        tdClass: 'text-left align-middle',
        thClass: 'text-left text-nowrap',
        thStyle: { minWidth: '120px' },
    },
    {
        key: 'attorney',
        i18nKey: 'brokerDealer.accreditation.table.labels.assignee',
        sortable: false,
        tdClass: 'text-left align-middle',
        thClass: 'text-left text-nowrap',
        thStyle: { minWidth: '120px' },
    },
    {
        key: 'id',
        label: '',
        tdClass: 'align-middle',
        thClass: 'border-top-0 text-center ',
        thStyle: { minWidth: '120px' },
    },
];
