<template>
  <div>
    <div class="header-block mb-4">
      <b-row>
        <b-col>
          <h4 class="font-weight-bold mb-0">
            <i class="sidenav-icon fas fa-user-tie icon" />
            {{ $t('brokerDealer.accreditation.prependTitle') }}
            <span class="text-muted font-weight-normal">
              |  {{ $t('brokerDealer.accreditation.title') }}
            </span>
          </h4>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>

export default {
  name: 'CpAccreditationHeader',
};
</script>

<style scope>
 .icon{
    font-size: 22px
  }
</style>
