<template>
  <cp-general-modal
    ref="accreditationDetail"
    :hide-footer="true"
    size="lg"
  >
    <template slot="modal-header">
      <span class="title">{{ $t('brokerDealer.accreditation.modal.title') }}</span>
      <div class="d-flex flex-row">
        <span class="assignee p-2">{{ $t('brokerDealer.accreditation.modal.assignee') }}: </span>
        <span>
          <b-tooltip
            :target="tooltipTargetId"
            triggers="hover"
          >
            {{ attorneyName }}
          </b-tooltip>
          <cp-input
            :id="tooltipTargetId"
            :value="attorneyName"
            name="Assignee"
            class="assignee-input mr-2"
            :disabled="true"
          />
        </span>
      </div>
      <button
        type="button"
        aria-label="Close"
        class="close"
        @click="hideModal"
      >
        ×
      </button>
    </template>
    <b-row>
      <b-col md="12">
        <div
          v-if="isLoading"
          class="spinner-container mx-auto text-center mt-2 modal-size"
        >
          <b-spinner
            v-if="isLoading"
            class="spinner"
          />
        </div>
      </b-col>
      <b-col
        v-if="!isLoading"
        md="12"
      >
        <div class="d-flex flex-row">
          <div class="d-flex flex-column content">
            <div class="d-flex line">
              <span class="label">{{ $t('brokerDealer.accreditation.modal.investorName') }}:</span>
              <span class="data">{{ accreditation.investorName }}</span>
            </div>
            <div class="d-flex line">
              <span class="label">{{ $t('brokerDealer.accreditation.modal.investorType') }}:</span>
              <span class="data">{{ accreditation.investorType }}</span>
            </div>
            <div class="d-flex line">
              <span class="label">{{ $t('brokerDealer.accreditation.modal.method') }}:</span>
              <span class="data">{{ accreditation.accreditationMethod }}</span>
            </div>
            <div class="d-flex line">
              <span class="label">{{ $t('brokerDealer.accreditation.modal.dateSubmitted') }}:</span>
              <span class="data">{{ createdAt }}</span>
            </div>
            <div class="d-flex line">
              <span class="label">{{ $t('brokerDealer.accreditation.modal.documentType') }}:</span>
              <span class="data">{{ accreditation.documentOrigin }}</span>
            </div>
            <div class="d-flex line">
              <span class="label">{{ $t('brokerDealer.accreditation.modal.email') }}:</span>
              <span class="data">{{ accreditation.investorEmail }}</span>
            </div>
          </div>
          <div class="d-flex flex-column content">
            <div
              v-if="accreditation.jointIncomeRange || accreditation.individualIncomeRange"
              class="d-flex line"
            >
              <span class="label">{{ $t('brokerDealer.accreditation.modal.individualIncome') }}:</span>
              <span class="data">{{ accreditation.individualIncomeRange }}</span>
            </div>
            <div
              v-if="accreditation.jointIncomeRange || accreditation.individualIncomeRange"
              class="d-flex line"
            >
              <span class="label">{{ $t('brokerDealer.accreditation.modal.jointIncome') }}:</span>
              <span class="data">{{ accreditation.jointIncomeRange }}</span>
            </div>
            <div
              v-if="accreditation.incomeRange"
              class="d-flex line"
            >
              <span class="label">{{ $t('brokerDealer.accreditation.modal.income') }}:</span>
              <span class="data">{{ accreditation.incomeRange }}</span>
            </div>
            <div class="d-flex line">
              <span class="label">{{ $t('brokerDealer.accreditation.modal.repType') }}:</span>
              <span class="data">{{ accreditation.representativeType }}</span>
            </div>
            <div class="d-flex line">
              <span class="label">{{ $t('brokerDealer.accreditation.modal.repNumber') }}:</span>
              <span class="data">{{ accreditation.representativeLicense }}</span>
            </div>
            <div class="d-flex line">
              <span class="label">{{ $t('brokerDealer.accreditation.modal.proLicenseType') }}:</span>
              <span class="data">{{ accreditation.licenseType }}</span>
            </div>
            <div class="d-flex line">
              <span class="label">{{ $t('brokerDealer.accreditation.modal.CRDNumber') }}:</span>
              <span class="data">{{ accreditation.crdNumber }}</span>
            </div>
          </div>
        </div>
        <hr>
        <div class="d-flex flex-row">
          <div class="d-flex flex-column content">
            <span class="select-title">{{ $t('brokerDealer.accreditation.modal.documents') }}</span>
            <div class="d-flex flex-row justify-content-start ">
              <div class="flex download-input">
                <span>{{ $t('brokerDealer.accreditation.modal.downloadInfo') }}</span>
              </div>
              <cp-button
                variant="info"
                class="download-button"
                @click="downloadZip"
              >
                <span class="font-500">{{ $t('brokerDealer.accreditation.modal.download') }}</span>
              </cp-button>
            </div>
          </div>
          <div class="d-flex flex-column flex-grow-1">
            <span class="select-title">{{ $t('brokerDealer.accreditation.modal.status') }}</span>
            <cp-select
              v-model="selectedStatus"
              name="status"
              :disabled="!isAssigned"
              class="select"
              :options="accreditationStatus.statuses"
            />
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="d-flex flex-column  flex-grow-1">
            <span class="select-title">{{ $t('brokerDealer.accreditation.modal.notes') }}:</span>
            <cp-textarea
              v-model="note"
              :placeholder="$t('brokerDealer.accreditation.modal.notesPlaceholder')"
              name="note"
              :rows="3"
            />
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="d-flex flex-column  flex-grow-1">
            <span class="select-title">{{ $t('brokerDealer.accreditation.modal.rejectReason') }}:</span>
            <cp-textarea
              v-model="rejectReason"
              class="m-0"
              :placeholder="$t('brokerDealer.accreditation.modal.notesPlaceholder')"
              name="rejectReason"
              :rows="3"
            />
            <span
              v-if="!isAssigned || !canSubmit"
              class="warning mt-2"
            ><b-img
              width="20"
              class="mr-2"
              :src="warningOutline"
            />{{ warningText }}</span>
          </div>
        </div>

        <div class="d-flex justify-content-center button-box">
          <cp-button
            size="lg"
            variant="info"
            class="button-text"
            :disabled="isLoadingAction || !isAssigned || !canSubmit"
            @click="changeReview"
          >
            <b-spinner
              v-if="isLoadingAction"
              class="spinner-button"
            />
            <span
              v-if="!isLoadingAction"
              class="font-500"
            >{{ $t('brokerDealer.accreditation.modal.changeReview') }}</span>
          </cp-button>
        </div>
      </b-col>
    </b-row>
  </cp-general-modal>
</template>
<script>
import moment from 'moment';
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import warningOutline from '@/assets/img/accreditations/warning-outline.svg';
import CpSelect from '@/components/common/standalone-components/inputs/cp-select';
import { accreditationStatus, accreditationNotes } from './options';
import CpButton from '@/components/common/standalone-components/cp-button';
import CpTextarea from '~/components/common/standalone-components/inputs/cp-textarea';
import { CpInput } from '@/components/common/standalone-components/inputs';

export default {
  name: 'AccreditationDetail',
  components: {
    CpGeneralModal,
    CpSelect,
    CpButton,
    CpTextarea,
    CpInput,
  },
  props: {
    accreditation: {
      type: Object,
      default: null,
    },
    operatorId: {
      type: Number,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      accreditationStatus,
      accreditationNotes,
      warningOutline,
      rejectReason: '',
      note: '',
      selectedStatus: '',
      assignee: '',
      isLoadingAction: false,
    };
  },
  computed: {
    tooltipTargetId() {
      return `input-operator-name-${this._uid}`;
    },
    createdAt() {
      return moment(this.accreditation.createdAt).format('MM.DD.YYYY');
    },
    isAssigned() {
      return !!(this.accreditation.attorney && this.operatorId === this.accreditation.attorney.operatorId);
    },
    attorneyName() {
      if (!this.isLoading && this.accreditation.attorney) {
        return this.accreditation.attorney.name;
      }
      return 'None';
    },
    warningText() {
      if (this.canSubmit) {
        return this.$t('brokerDealer.accreditation.modal.assignWarning');
      }
      return this.$t('brokerDealer.accreditation.modal.reasonWarning');
    },
    canSubmit() {
      return !((this.selectedStatus === accreditationStatus.names.UPDATES_REQUIRED
          || this.selectedStatus === accreditationStatus.names.EXPIRED
          || this.selectedStatus === accreditationStatus.names.REJECTED)
          && !this.rejectReason);
    },
  },
  watch: {
    accreditation(newVal) {
      this.isLoadingAction = false;
      this.selectedStatus = newVal.status;
      this.rejectReason = newVal.rejectReason;
      this.note = newVal.note;
    },
  },
  methods: {
    hideModal() {
      this.$refs.accreditationDetail.hide();
    },
    downloadZip() {
      this.$emit('onDownload', this.accreditation.id);
    },
    changeReview() {
      this.isLoadingAction = true;
      this.$emit('onChangeReview', this.accreditation.id, this.selectedStatus, this.rejectReason, this.note);
    },
  },
};
</script>

<style scoped lang="scss">
  .font-500 {
    font-weight: 500;
  }

  .modal-header {
    padding: 10px;
  }

  .title {
    font-weight: 500;
    font-size: 16px;
    color: #617D8D;
  }

  .assignee {
    font-size: 14px;
  }

  .label {
    font-weight: 500;
    flex-basis: 130px;
    color: #617D8D;
  }

  .data {
    color: #617D8D;
  }

  .content {
    flex-grow: 0.5;
    margin-bottom: 20px;
  }

  .line {
    margin-bottom: 9px;
  }

  .download-input {
    border: 1px solid rgba(10, 23, 39, 0.1);
    padding: 0.438rem 0.875rem;
    border-radius: 0.25rem;
    flex-basis: 270px;
    height: calc(1.54em + 0.876rem + 2px);
    margin-right: 10px;
  }

  .download-icon {
    cursor: pointer;
  }

  .select-title {
    color: #0A1828;
    font-size: 16px;
    margin-bottom: 12px;
  }

  .select-box {
    flex-grow: 1;
    margin-right: 12px;
  }

  .select {
    flex-grow: 0.5;
  }

  .button-box {
    margin-top: 30px;
  }

  .assignee-input {
    max-width: 113px;
  }

  .download-button {
    width: 102px;
    padding: 3px 5px;
  }

  .button-text {
    min-height: 24px;
    min-width: 158px;
  }

  .spinner-button {
    height: 25px;
    width: 25px
  }

  .warning {
    color: #F49E39;
  }
</style>
