<template>
  <multiselect
    ref="vueSelect"
    v-model="selected"
    :class="{ 'is-active': selected.length }"
    class="market-multiselect"
    :options="options"
    :multiple="true"
    :close-on-select="false"
    :clear-on-select="false"
    :preserve-search="true"
    :placeholder="placeholder"
    label="text"
    track-by="value"
    :searchable="searchable"
    :show-labels="false"
  >
    <template
      slot="tag"
    >
      {{ null }}
    </template>
    <template
      slot="selection"
      slot-scope="{ values, search, isOpen }"
    >
      <p
        v-if="values.length && !isOpen"
        class="d-flex align-items-center mb-0"
      >
        {{ placeholder }}
        <label class="selected-items-count">
          {{ values.length }}
        </label>
      </p>
    </template>
    <template
      slot="option"
      slot-scope="{ option }"
    >
      <div
        class="d-flex align-items-center"
      >
        <b-form-checkbox
          disabled
          :checked="findValue(option)"
        />
        <span class="option__title">{{ option.i18nKey ? $t(option.i18nKey) : option.text }}</span>
      </div>
    </template>
    <template slot="afterList">
      <div class="actions-section">
        <hr class="m-2">
        <div class="d-flex align-items-center justify-content-between action-buttons-container mb-2">
          <b-btn
            variant="link"
            class="clear-btn"
            @click="clear"
            v-text="'Clear'"
          />
          <b-btn
            variant="primary"
            @click="apply"
            v-text="'Save'"
          />
        </div>
      </div>
    </template>
    <template
      slot="noResult"
    >
      <div class="d-flex align-items-center mt-2">
        No results found.
      </div>
    </template>
    <template
      slot="noOptions"
    >
      <div class="d-flex align-items-center mt-2">
        No options available.
      </div>
    </template>
  </multiselect>
</template>

<script>

import Multiselect from 'vue-multiselect';

export default {
  name: 'MarketMultiSelect',
  components: {
    Multiselect,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    defaultSelected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: [],
    };
  },
  watch: {
    defaultSelected: {
      handler(newVal) {
        this.selected = newVal || [];
      },
      immediate: true,
    },
  },
  methods: {
    clear() {
      this.selected = [];
      this.apply();
    },
    apply() {
      this.$refs.vueSelect.isOpen = false;
      this.$emit('filtersChanged', { name: this.name, filters: this.selected });
    },
    findValue(option) {
      return this.selected.some(({ value }) => value === option.value);
    },
    deleteFilter(value) {
      this.selected = [...this.selected.filter(filter => filter.value !== value)];
      this.apply();
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>
<style lang="scss">
  .market-multiselect {
    .is-active {
      border-radius: 3px;
      border: solid 1px #617d8d;
      background-color: #ffffff;
    }

    .actions-section {
      position: sticky;
      background: #FFF;
      bottom: 0;
      padding-bottom: 0.2em;
      .action-buttons-container {
        margin: 0 12px;
      }
    }

    .multiselect__option--selected, .multiselect__option--highlight, .multiselect__option--selected.multiselect__option--highlight {
      background: #FFF !important;
      color: #35495e !important;;
      font-weight: unset !important;;
    }

    .multiselect__content-wrapper {
      margin-top: 0.5em;
      border: 1px solid #e5e5e5 !important;

      .multiselect__content {
        max-width: 100%;

        .clear-btn, .clear-btn:hover {
          text-decoration: underline;
        }

        .clear-btn {
          color: #c4c4c4;
          padding-left: 0;
        }
      }

      .option {
        &__title {
          text-transform: capitalize;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .multiselect__tags {
      display: flex !important;
      align-items: center !important;
      padding-top: 0 !important;
      justify-content: center;

      .selected-items-count {
        background: #617d8d;
        padding: 0.1em 0.3em;
        margin: 0 0 0 0.5em;
        color: #FFF;
        font-weight: 500;
        font-size: 14px;
      }

      input {
        margin-bottom: 0;
      }
    }

    .multiselect__placeholder {
      margin-bottom: 0 !important;
    }
  }
</style>
