<template>
  <div class="d-flex flex-column mb-3">
    <div class="form-row align-items-center mx-0">
      <div class="filter">
        <market-multi-select
          ref="status"
          :options="accreditationStatus.statuses"
          :placeholder="$t('brokerDealer.accreditation.filters.accreditationStatus')"
          name="status"
          @filtersChanged="onFiltersChange"
        />
      </div>
      <div class="filter">
        <market-multi-select
          ref="investor-type"
          :options="investorTypes.types"
          :placeholder="$t('brokerDealer.accreditation.filters.investorType')"
          name="investor-type"
          @filtersChanged="onFiltersChange"
        />
      </div>
      <div class="filter">
        <market-multi-select
          ref="attorney-id"
          :options="attorneysFilter"
          :placeholder="$t('brokerDealer.accreditation.filters.assignees')"
          name="attorney-id"
          @filtersChanged="onFiltersChange"
        />
      </div>
    </div>
    <div class="date-filters">
      <date-range
        v-validate="'date_range'"
        class="input-item"
        name="datePicker"
        :label="$t('brokerDealer.accreditation.filters.date')"
        @input="onDateRangeChange"
      />
    </div>

    <div class="d-flex align-items-center mt-4 flex-wrap">
      <div
        v-for="filter in activeFilters"
        :key="filter.value"
        class="selected-filter"
      >
        <span class="mb-0">{{ $t(filter.i18nKey) || filter.text }}</span>
        <i
          class="ion ion-ios-close ml-2"
          @click="() => removeFilter(filter)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { kebabCase } from 'change-case';
import { accreditationStatus, investorTypes } from './options';
import MarketMultiSelect from '@/pages/broker-dealer/investors/components/market-multiselect';
import DateRange from './date-range';

export default {
  name: 'BrokerDealerInvestorFilter',
  components: { MarketMultiSelect, DateRange },
  props: {
    attorneys: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      registrationSourceList: [],
      accreditationStatus,
      investorTypes,
      appliedFilters: {},
      activeFilters: [],
      dateFilters: {},
    };
  },
  computed: {
    attorneysFilter() {
      return [{ value: 'none', text: 'None' },
        ...this.attorneys.map(item => (
          {
            value: item.id,
            text: item.name,
          }
        ))];
    },
  },
  methods: {
    onFiltersChange({ name: filterKey, filters }) {
      this.appliedFilters[kebabCase(filterKey)] = filters;
      this.activeFilters = Object.keys(this.appliedFilters)
        .reduce((accum, key) => ([...accum, ...this.appliedFilters[key].map(filter => ({ ...filter, key }))]), []);
      const filterValues = Object.keys(this.appliedFilters)
        .reduce((accum, key) => ({ ...accum, [key]: this.appliedFilters[key].map(({ value }) => value) }), {});
      this.$emit('filtersApplied', filterValues, this.dateFilters);
    },
    removeFilter(filter) {
      this.$refs[filter.key].deleteFilter(filter.value);
    },
    onDateRangeChange(dates) {
      const [startDate, endDate] = dates;
      this.dateFilters = {
        'from-updated-at': startDate,
        'to-updated-at': endDate,
      };
      const filterValues = Object.keys(this.appliedFilters)
        .reduce((accum, key) => ({ ...accum, [key]: this.appliedFilters[key].map(({ value }) => value) }), {});
      this.$emit('filtersApplied', filterValues, this.dateFilters);
    },
  },
};
</script>

<style lang="scss" scoped>
  .filter {
    margin-right: 20px;
    width: 200px;
    margin-top: 9px;
  }

  .date-filters {
    margin-top: 1em;
    display: flex;
  }

  .selected-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em 1em;
    border-radius: 50px;
    background-color: rgba(97, 125, 141, 0.1);
    margin: 0 1em 1em 0;
    font-size: 14px;
    font-weight: 500;
    color: #697e8d;
    text-transform: capitalize;
    i {
      font-size: 24px;
      cursor: pointer;
    }
  }
</style>
