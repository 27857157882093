<template>
  <div class="markets-investor-table-container">
    <cp-table
      ref="accreditationTable"
      get-data-action="brokerDealerAccreditations/getBrokerDealerAccreditationsList"
      :fields="tableFields"
      :data-parser="parseData"
      :search-value="searchQueryParam"
      :filters="filters"
      rows-clickable
      @tableDataUpdated="onTableDataUpdated"
      @rowClicked="onRowClicked"
      @onSort="$emit(onSort, $event)"
    >
      <template slot="tableHeader">
        <span class="d-flex justify-content-between">
          <div class="d-flex p-4">
            <span class="header-title">
              {{ $t('brokerDealer.accreditation.title') }}
            </span>
          </div>
          <div class="d-flex p-4 ">
            <cp-button
              :disabled="disabledExport()"
              :variant="`${disabledExport() ? 'outline-secondary disabled': 'outline-primary'}`"
              icon="ios-download"
              :is-loading="downloading"
              size="sm"
              @click="exportData"
            >  {{ $t('investorsCommon.button.table.export') }}
            </cp-button>
          </div>
        </span>
      </template>
      <template
        slot="status"
        slot-scope="{ rowData }"
      >
        <b-badge
          class="align-text-bottom"
          :class="accreditationStatusesColor[rowData.item.status]"
        >
          {{ rowData.item.status }}
        </b-badge>
      </template>
      <template
        slot="attorney"
        slot-scope="{ rowData }"
      >
        <b-select
          :disabled="disabledByAssigningAttorney(rowData.item.id)"
          injected-classes="m-0"
          :value="rowData.item.attorney !== undefined ? rowData.value.operatorId : null"
          :options="assignee"
          @click.native.stop
          @input="attorneyAssigneeChange(rowData.item.id, $event, rowData.item.attorney)"
        />
      </template>
      <template
        slot="id"
        slot-scope="{ rowData }"
      >
        <cp-button
          size="sm"
          variant="info"
          :disabled="disabledByAssigningAttorney(rowData.item.id)"
          :value="rowData.value"
          class="button-text"
          @click="getDetails(rowData.value)"
        >
          {{ $t('brokerDealer.accreditation.table.labels.view') }}
        </cp-button>
      </template>
    </cp-table>
  </div>
</template>

<script>
import moment from 'moment';
import CpTable from '~/components/shared/cp-table';
import CpTimeConverter from '~/mixins/time-converter';
import CpButton from '~/components/common/standalone-components/cp-button';
import { accreditationStatusesColor, tableFields } from './options';


export default {
  name: 'CpAccreditationTable',
  components: {
    CpTable,
    CpButton,
  },
  mixins: [
    CpTimeConverter,
  ],
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
    attorneys: {
      type: Array,
      required: true,
    },
    assigningAccreditationId: {
      type: String,
      default: null,
    },
    downloading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableFields,
      accreditationStatusesColor,
      totalTableData: [],
      searchQueryParam: this.$route.query.search || undefined,
      isEmptyList: false,
    };
  },
  computed: {
    assignee() {
      return [{ value: null, text: 'None' },
        ...this.attorneys.map(item => (
          {
            value: item.operatorId,
            text: item.name,
          }
        ))];
    },
  },
  watch: {
    $route(to) {
      this.searchQueryParam = to.query.search;
    },
  },
  methods: {
    disabledByAssigningAttorney(accreditationId) {
      return this.assigningAccreditationId === accreditationId;
    },
    onTableDataUpdated({ items, totalItems }) {
      this.totalTableData = items;
      this.isEmptyList = totalItems === 0;
    },
    getDetails(id) {
      if (!this.disabledByAssigningAttorney(id)) {
        this.$emit('onDetail', id);
      }
    },
    parseData(data) {
      return data.map(item => ({
        ...item,
        createdAt: moment(item.createdAt).format('ll'),
        accreditationMethod: this.$t(`brokerDealer.accreditation.table.data.${item.accreditationMethod}`),
        investorType: this.$t(`brokerDealer.accreditation.table.data.${item.investorType}`),
        documentOrigin: this.$t(`brokerDealer.accreditation.table.data.${item.documentOrigin}`),
        status: this.$t(`brokerDealer.accreditation.verificationTab.accreditationStatuses.${item.status}`),
      }));
    },
    onRowClicked({ data: item }) {
      if (!this.disabledByAssigningAttorney(item.id)) {
        this.$emit('onViewElem', item.id);
      }
    },
    attorneyAssigneeChange(accreditationId, operatorId, assignedAttorneyOperatorId) {
      if (operatorId === assignedAttorneyOperatorId) return;
      this.$emit('assigneeAttorney', { accreditationId, operatorId });
    },
    disabledExport() {
      if (this.$refs.accreditationTable) {
        const { items } = this.$refs.accreditationTable;
        return !(items.length && items.length > 0);
      }
      return false;
    },
    exportData() {
      const { items, queryParams, filters } = this.$refs.accreditationTable;
      this.$emit('onExportList', { items, queryParams, filters });
    },
  },
};
</script>

<style scoped lang="scss">
  .download {
    flex-flow: row-reverse;
  }
  .disabled{
    cursor: not-allowed;
  }
  .button-text {
    font-size: 14px;
    font-weight: 500;
  }
  .headers-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
  .input-group{
    width: auto;
  }

  .header-title{
    color: #617D8D;
    font-size: 16px;
  }
  .markets-investor-table-container {
    color: white;
    .badge{
      font-size: 0.7em;
    }
    .badge-grey, .badge-orange, .badge-yellow, .badge-green, .badge-red, .badge-secondary {
      text-transform: capitalize;
      padding: 0.5em 1em;
      border-radius: 2px;
      color: white;
      width: 6rem;
    }
    .badge-grey {
      background-color: #dee0e2;
      -webkit-box-shadow: 0 0 0 1px #dee0e2 inset;
      box-shadow: 0 0 0 1px #dee0e2 inset;
    }
    .badge-orange {
      background-color: #F5A01D;
      -webkit-box-shadow: 0 0 0 1px #F5A01D inset;
      box-shadow: 0 0 0 1px #F5A01D inset;
    }
    .badge-yellow {
      background-color: #FADA69;
      -webkit-box-shadow: 0 0 0 1px #ffd950 inset;
      box-shadow: 0 0 0 1px #ffd950 inset;
    }
    .badge-green {
      background-color: #07bc77;
      -webkit-box-shadow: 0 0 0 1px #07bc77 inset;
      box-shadow: 0 0 0 1px #07bc77 inset;
    }
    .badge-red {
      background-color: #f7321f;
      -webkit-box-shadow: 0 0 0 1px #f7321f inset;
      box-shadow: 0 0 0 1px #f7321f inset;
    }
  }
</style>
